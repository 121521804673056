import React from "react"
import { splitText } from "@utils"

export const Section = ({ children, className = "col-span-10" }) => {
  return (
    <div className="container flex justify-center">
      <div className="grid-cols-10 md:w-10/12 lg:grid">
        <div className={className}>{children}</div>
      </div>
    </div>
  )
}

const Title = ({ children, className = "text-gray-darkest" }) => {
  return (
    <h3
      data-aos="stagger"
      className="text-clamp-25-40"
      dangerouslySetInnerHTML={{
        __html: splitText({
          className: children,
        }),
      }}
    />
  )
}

const Description = ({ children }) => {
  return (
    <div className="mt-6" data-aos="fade-up">
      {children}
    </div>
  )
}

Section.Title = Title
Section.Description = Description

import React from "react"
import PropTypes from "prop-types"
import { Button } from "@components/Button"
import { splitText } from "@utils"

export const Intro = ({ children }) => {
  return (
    <section>
      <div className="container flex mt-10 sm:mt-12">
        <div className="w-full grid-cols-6 lg:w-11/12 lg:ml-auto sm:grid lg:grid-cols-10">{children}</div>
      </div>
    </section>
  )
}

const Overview = ({ children }) => {
  return (
    <div data-aos="fade-up" className="grid grid-cols-8 col-span-4 lg:col-span-7 xl:col-span-8 lg:grid-cols-16">
      <div className="col-span-7 lg:col-span-13">{children}</div>
    </div>
  )
}

const Title = ({children}) => {
  return (<h3
    data-aos="stagger"
    className="text-clamp-25-40"
    dangerouslySetInnerHTML={{
      __html: splitText({
        className: children,
      }),
    }}
  />)
}

const Deliverables = ({ children, url = "" }) => {
  return (
    <div data-aos="fade-up" className="col-span-2 mt-20 lg:col-span-3 xl:col-span-2 sm:mt-0">
      <h3 className="text-clamp-16-18">Deliverables</h3>
      <div className="mt-6">
        <ul className="list-reset text-clamp-16-18 gap-y-3">{children}</ul>
      </div>
      {url.trim() !== "" && (
        <div className="mt-15">
          <Button link={url} className="text-gray-darkest sm:hover:text-teal" external={true}>
            Visit live
          </Button>
        </div>
      )}
    </div>
  )
}

Intro.Overview = Overview
Intro.Deliverables = Deliverables
Intro.Title = Title

Intro.propTypes = {
  children: PropTypes.node,
}

Overview.propTypes = {
  children: PropTypes.node,
}

Title.propTypes = {
  children: PropTypes.node
}

Deliverables.propTypes = {
  children: PropTypes.node,
  url: PropTypes.string,
}

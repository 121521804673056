import React from "react"

export const SocialDesign = ({ children }) => {
  return (
    <div className="container flex justify-center">
      <div className="w-full xl:w-10/12">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-4 gap-y-clamp-12-10">{children}</div>
      </div>
    </div>
  )
}

const Card = ({ children, className }) => {
  return (
    <div className="flex">
      <div className={`sm:w-4/6 lg:w-full ${className}`}>
        <div data-aos="fade-up">{children}</div>
      </div>
    </div>
  )
}

SocialDesign.Card = Card

import React from "react"

export const Category = ({ children }) => {
  return (
    <div className="container flex mt-28">
      <div className="w-full mx-auto lg:w-10/12">
        <h2 data-aos="fade-up" className="text-14">
          Category <span className="ml-4 font-normal font-book">{children}</span>
        </h2>
      </div>
    </div>
  )
}

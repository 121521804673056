import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Vimeo from "@u-wave/react-vimeo"
import { usePageSetup } from "@components/usePageSetup"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { Hero } from "@components/Hero"
import { HeroImage, Intro, Category, MobileDesign, Pagination, Section } from "@components/Work"
import { Carousel } from "@components/Carousel"
import { CursorMarker } from "@components/CursorMarker"
import { Arrow } from "@components/UI"
import { splitText } from "@utils"
import workData from "@json/work.json"

const WarnerBrosPage = ({ data }) => {
  const {
    designOneImage,
    designTwoImage,
    designThreeImage,
    webpageOneImage,
    webpageTwoImage,
    webpageThreeImage,
    printOneDesktopImage,
    printOneTabletImage,
    printTwoDesktopImage,
    printTwoTabletImage,
    printThreeDesktopImage,
    printThreeTabletImage,
    printFourTabletImage,
    mobileOneImage,
    mobileTwoImage,
    mobileThreeImage,
  } = data
  const { name, headline, theme, url } = workData["wb"]
  const { themeColor } = usePageSetup({
    themeColor: theme.background,
    navigationColor: theme.navigationColor,
    displayGeneralForm: false,
  })

  return (
    <Layout>
      <Seo title={name} description={headline} />
      {/* HERO */}
      <section className={themeColor}>
        <Hero className="pb-clamp-36-54 pt-clamp-16-26">
          <Hero.Title>
            <h1 className="tracking-normal text-white text-18">{name}</h1>
            <h2
              data-aos="stagger"
              className="text-work-headline mt-clamp-6-8"
              dangerouslySetInnerHTML={{
                __html: splitText({
                  "text-white": headline,
                }),
              }}
            />
          </Hero.Title>
        </Hero>
      </section>

      <Arrow colorTop="bg-white" colorBottom="bg-white" fillColor="text-white" />

      <main className="overflow-hidden">
        <HeroImage client="wb" />
        <Category>E-commerce design system</Category>
        <Intro>
          <Intro.Overview>
            <Intro.Title>How do you better connect with fans? Tell stories they care about.</Intro.Title>
            <div className="mt-10">
              <p>
              WB wanted their global online storefront “WB The Shop” to stand out from the competition and give their fans an experience that would keep them coming back for more.
              </p>
              <p>
              So they relied on JK’s storytelling skills to help them make deeper connections with the people who love their properties most.
              </p>
            </div>
          </Intro.Overview>
          <Intro.Deliverables url={url}>
            <li>Design system</li>
            <li>Wireframes</li>
            <li>Journey maps</li>
            <li>Website design</li>
          </Intro.Deliverables>
        </Intro>

        <section className="mt-clamp-34-29">
          <div className="container flex justify-center">
            <div className="w-full lg:w-10/12">
              <div data-aos="fade-up">
                <div  className="overflow-hidden rounded-lg shadow">
                  <Vimeo video="741967692" controls={false} responsive muted autoplay loop background	 />
                </div>
              </div>
            </div>
          </div>
        </section>

        <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

        <section className="pt-clamp-38-71">
          <Section className="col-span-9 xl:col-span-8">
            <Section.Title>Fanning the flames of fandom.</Section.Title>
            <Section.Description>
              <p>
              People are willing to spend more when they have a good customer experience. For WB, that meant focusing on the most dedicated admirers of their movies and shows. So we developed a design system that makes it easy for these fans to truly connect with the properties they love, which in turn creates new opportunities for The Shop to thrive.
              </p>
            </Section.Description>
          </Section>

          <div className={`relative mt-clamp-24-30`} style={{background: "#323445"}}>
            <div className="absolute inset-0">
              <div className="container">
                <div className="w-full aspect-w-667 aspect-h-310 lg:aspect-w-1436 lg:aspect-h-240 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
              </div>
            </div>
            <div className="absolute inset-x-0 bottom-0">
              <div className="container">
                <div className="w-full aspect-w-667 aspect-h-78 lg:aspect-w-1436 lg:aspect-h-100 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
              </div>
            </div>
            <div className="container grid grid-cols-12 gap-x-2 lg:gap-x-4 grid-row-1">
              <div className="grid grid-cols-12 col-span-8 col-start-1 row-start-1 row-end-2 lg:col-end-7 lg:z-0">
                <div className="col-span-11 lg:col-span-11">
                  <div data-aos="fade-up">
                    <GatsbyImage
                      objectFit="contain"
                      className="overflow-hidden rounded-lg shadow"
                      image={getImage(designOneImage)}
                      alt="Webpage - 1"
                    />
                  </div>
                </div>
              </div>
              <div className="z-20 col-start-3 col-end-11 row-start-1 row-end-2 lg:col-start-4 lg:col-end-10">
                <div className="aspect-w-441 aspect-h-669 lg:aspect-w-710 lg:aspect-h-375"></div>
                <div className="lg:px-8">
                  <div data-aos="fade-up">
                    <GatsbyImage
                      objectFit="contain"
                      className="overflow-hidden rounded-lg shadow"
                      image={getImage(designTwoImage)}
                      alt="Webpage - 2"
                    />
                  </div>
                </div>
              </div>
              <div className="z-10 grid-cols-12 col-start-5 col-end-13 row-start-1 row-end-2 lg:col-start-7 lg:col-end-13 lg:grid">
                <div className="col-span-11 col-start-2">
                  <div className="aspect-w-415 aspect-h-170 lg:aspect-w-710 lg:aspect-h-110"></div>
                  <div data-aos="fade-up">
                    <GatsbyImage
                      objectFit="contain"
                      className="overflow-hidden rounded-lg shadow"
                      image={getImage(designThreeImage)}
                      alt="Webpage - 3"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative pt-clamp-15-42">
          <Section className="col-span-9 xl:col-span-8">
            <Section.Title>Visual storytelling that sells.</Section.Title>
            <Section.Description>
              <p>
              WB has a deep library of media for each of its properties, so our design system is built around visual storytelling—showcasing the epic scenes and special moments that fans remember most.
              </p>
              <p>
              This approach draws fans deeper into the worlds they love and creates a strong narrative thread through the products they explore on The Shop. So each purchase becomes an affirmation of their fandom—drawing them deeper into the stories and places they love.              </p>
            </Section.Description>
          </Section>
          <div className="mt-32">
            <MobileDesign>
              <MobileDesign.Phone className="phone-1">
                <GatsbyImage
                  objectFit="contain"
                  className="overflow-hidden shadow rounded-3xl"
                  image={getImage(mobileOneImage)}
                  alt="Weichert mobile - 1"
                />
              </MobileDesign.Phone>
              <MobileDesign.Phone className="phone-2 sm:pt-clamp-35-62">
                <div className="relative">
                  <div className="absolute inset-0">
                    <div className="overflow-hidden rounded-lg shadow">
                      <Vimeo  video="743187984" controls={false} quality="1080p" responsive muted autoplay loop background />
                    </div>
                  </div>
                  <GatsbyImage
                    objectFit="contain"
                    className="invisible overflow-hidden shadow rounded-3xl"
                    image={getImage(mobileTwoImage)}
                    alt="Weichert mobile - 2"
                  />
                </div>
              </MobileDesign.Phone>
              <MobileDesign.Phone className="phone-3">
                <GatsbyImage
                  objectFit="contain"
                  className="overflow-hidden shadow rounded-3xl"
                  image={getImage(mobileThreeImage)}
                  alt="Weichert mobile - 3"
                />
              </MobileDesign.Phone>
            </MobileDesign>
          </div>
        </section>

        <section className="pt-clamp-35-50">
          <Section className="col-span-9 xl:col-span-8">
            <Section.Title>A site that never sits still.</Section.Title>
            <Section.Description>
              <p>
              The design system JK developed not only makes WB’s properties look great—it also makes it simple for WB’s internal merchandising team to update The Shop with new properties and products. That means the site stays fresh, engaging, and appealing to repeat customers.
              </p>
              <p>
              We also created detailed creative guidance that makes it easy for the WB team to tell better visual stories, incorporate narrative elements in their pages, and connect with fans in more meaningful ways.
              </p>
            </Section.Description>
          </Section>

          <div className="container relative mt-clamp-18-25">
            <Carousel>
              <CursorMarker>
                <CursorMarker.Print />
              </CursorMarker>
              <Carousel.Swiper
                breakpoints={{
                  1436: {
                    spaceBetween: 80,
                    slidesPerView: "auto",
                  },
                }}
              >
                <Carousel.Slide>
                  <div data-aos="fade-up">
                    <Carousel.Print>
                      <div className="display-mobile-tablet">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printOneTabletImage)}
                          alt="Print 1"
                        />
                      </div>
                      <div className="display-desktop">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printOneDesktopImage)}
                          alt="Print 1"
                        />
                      </div>
                    </Carousel.Print>
                  </div>
                </Carousel.Slide>
                <Carousel.Slide>
                  <div data-aos="fade-up" data-aos-delay="200">
                    <Carousel.Print>
                      <div className="display-mobile-tablet">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printTwoTabletImage)}
                          alt="Print 2"
                        />
                      </div>
                      <div className="display-desktop">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printTwoDesktopImage)}
                          alt="Print 2"
                        />
                      </div>
                    </Carousel.Print>
                  </div>
                </Carousel.Slide>
                <Carousel.Slide>
                  <div data-aos="fade-up" data-aos-delay="200">
                    <Carousel.Print>
                      <div className="display-mobile-tablet">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printThreeTabletImage)}
                          alt="Print 3"
                        />
                      </div>
                      <div className="display-desktop">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printThreeDesktopImage)}
                          alt="Print 3"
                        />
                      </div>
                    </Carousel.Print>
                  </div>
                </Carousel.Slide>
                <Carousel.Slide>
                  <div data-aos="fade-up" data-aos-delay="200">
                    <Carousel.Print>
                      <div className="display-mobile-tablet">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printFourTabletImage)}
                          alt="Print 3"
                        />
                      </div>
                    </Carousel.Print>
                  </div>
                </Carousel.Slide>
              </Carousel.Swiper>
            </Carousel>
          </div>
        </section>



        <section className="pt-clamp-35-46 pb-clamp-42-62">
          <Section className="col-span-7 xl:col-span-6">
            <Section.Title>A relationship designed to grow.</Section.Title>
            <Section.Description>
              <p>
              As we developed the global design system, we illustrated how it would look by designing a handful of examples that used the system to its maximum effect.
              </p>
              <p>
              WB was so pleased with these designs that they used them for the site and asked us to create several more, extending the scope of the project and deepening our relationship with this legendary Hollywood studio.
              </p>
            </Section.Description>
          </Section>
          <div className="container flex justify-center mt-15 lg:mt-0">
            <div className="grid w-full grid-cols-6 grid-rows-1 gap-4 lg:gap-6 md:w-10/12 lg:grid-cols-20">
              <div className="col-start-1 col-end-5 row-start-1 row-end-2 lg:col-end-10">
                <div className="lg:aspect-w-525 lg:aspect-h-110"></div>
                <div data-aos="fade-up" className="overflow-hidden rounded shadow-xl">
                  <GatsbyImage image={getImage(webpageOneImage)} alt="WB webpage - 1" />
                </div>
              </div>
              <div className="col-start-3 col-end-7 row-start-1 row-end-2 lg:col-start-10 lg:col-end-19">
                <div className="aspect-w-441 aspect-h-647 lg:aspect-w-525 lg:aspect-h-317"></div>
                <div data-aos="fade-up" className="overflow-hidden rounded shadow-xl">
                  <GatsbyImage image={getImage(webpageTwoImage)} alt="WB webpage - 2" />
                </div>
              </div>
              <div className="col-start-5 col-end-7 row-start-1 row-end-2 lg:col-start-16 lg:col-end-21">
                <div className="aspect-w-214 aspect-h-110 lg:hidden"></div>
                <div data-aos="fade-up" className="overflow-hidden rounded shadow-xl">
                  <GatsbyImage image={getImage(webpageThreeImage)} alt="WB webpage - 3" />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="container relative flex justify-center mt-clamp-25-34">
            <div className="absolute inset-0">
              <div className="w-full aspect-w-1436 aspect-h-243 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
            </div>
            <div className="flex flex-col w-full lg:flex-row gap-x-4">
              <div className="flex w-full lg:block lg:w-1/2 z-1">
                <div className="w-full sm:w-5/6 lg:w-full">
                  <div data-aos="fade-up" className="overflow-hidden rounded shadow-xl">
                    <GatsbyImage image={getImage(webpageOneImage)} alt="AD webpage - 1" />
                  </div>
                </div>
              </div>
              <div className="flex w-full lg:block lg:w-1/2 pt-clamp-14-37 sm:pt-0 sm:-mt-10 lg:mt-0 lg:pt-clamp-14-37">
                <div className="w-full ml-auto sm:w-5/6 lg:w-full">
                  <div data-aos="fade-up" className="overflow-hidden rounded shadow-xl">
                    <GatsbyImage image={getImage(webpageTwoImage)} alt="AD webpage - 2" />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </section>

        <Arrow colorTop="bg-gray-darkest" colorBottom="bg-white" fillColor="text-white" />
        <Pagination client="wb" />
      </main>
    </Layout>
  )
}

export default WarnerBrosPage

export const warnerBrosQuery = graphql`
  query WarnerBrosPageQuery {
    designOneImage: file(relativePath: { eq: "work/wb/design-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    designTwoImage: file(relativePath: { eq: "work/wb/design-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    designThreeImage: file(relativePath: { eq: "work/wb/design-03.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    mobileOneImage: file(relativePath: { eq: "work/wb/mobile-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    mobileTwoImage: file(relativePath: { eq: "work/wb/mobile-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    mobileThreeImage: file(relativePath: { eq: "work/wb/mobile-03.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printOneDesktopImage: file(relativePath: { eq: "work/wb/print-01-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printOneTabletImage: file(relativePath: { eq: "work/wb/print-01-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printTwoDesktopImage: file(relativePath: { eq: "work/wb/print-02-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printTwoTabletImage: file(relativePath: { eq: "work/wb/print-02-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printThreeDesktopImage: file(relativePath: { eq: "work/wb/print-03-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printThreeTabletImage: file(relativePath: { eq: "work/wb/print-03-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printFourTabletImage: file(relativePath: { eq: "work/wb/print-04-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    webpageOneImage: file(relativePath: { eq: "work/wb/webpage-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    webpageTwoImage: file(relativePath: { eq: "work/wb/webpage-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    webpageThreeImage: file(relativePath: { eq: "work/wb/webpage-03.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`

import React from "react"

export const MobileDesign = ({ children }) => {
  return (
    <div className="relative container flex justify-center">
      <div className="grid-work-mobile lg:w-10/12 gap-y-6 gap-x-clamp-4-8">{children}</div>
    </div>
  )
}

const Phone = ({ children, className }) => {
  return (
    <div data-aos="fade-up" className={`${className} flex items-center sm:block`}>
      {children}
    </div>
  )
}

MobileDesign.Phone = Phone

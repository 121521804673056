import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ArrowPointer from "@svg/ui/arrow-pointer.svg"
import workData from "@json/work.json"

export const FeaturedWithDescription = ({ client }) => {
  const { slug, name, headline, theme } = workData[client]
  const images = useStaticQuery(query)

  return (
    <div data-aos="fade-up">
      <Link to={`/work/${slug}`}>
        <div className="relative grid grid-cols-5 aspect-w-317 aspect-h-449 sm:aspect-w-328 sm:aspect-h-464 lg:aspect-w-658 lg:aspect-h-790">
          <div className="zoom" style={{ position: "absolute" }}>
            <div className="inset-0 zoom-image">
              <div className="absolute inset-0 hidden xl:block">
                <GatsbyImage className="w-full h-full" image={getImage(images[`${client}Desktop`])} alt={name} />
              </div>
              <div className="hidden w-full h-full lg:block xl:hidden">
                <GatsbyImage className="w-full h-full" image={getImage(images[`${client}Tablet`])} alt={name} />
              </div>
              <div className="block w-full h-full lg:hidden">
                <GatsbyImage className="w-full h-full" image={getImage(images[`${client}Mobile`])} alt={name} />
              </div>
            </div>
          </div>
          <div className="flex col-span-4 col-start-2 pointer-events-none">
            <div className="w-full mt-auto">
              <div
                className={`${theme.background} block transform translate-y-20 aspect-w-329 aspect-h-211 xl:aspect-w-537 xl:aspect-h-189`}
              >
                <div className="flex items-center pr-8 pl-clamp-7-16 xl:pr-10">
                  <div className={`${theme.navigationColor} w-full xl:flex items-center justify-between flex-row`}>
                    <div style={{ maxWidth: "330px" }}>
                      <h3 className="leading-none text-clamp-26-32">{name}</h3>
                      <p className="mt-2 text-16 xl:text-18">{headline}</p>
                    </div>
                    <div className="mt-5 fill-current stroke-current xl:mt-0">
                      <ArrowPointer />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

FeaturedWithDescription.propTypes = {
  client: PropTypes.string.isRequired,
}

const query = graphql`
  query {
    adDesktop: file(relativePath: { eq: "work/ad/featured-description-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    adTablet: file(relativePath: { eq: "work/ad/featured-description-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    adMobile: file(relativePath: { eq: "work/ad/featured-description-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    bpmDesktop: file(relativePath: { eq: "work/bpm/featured-description-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    bpmTablet: file(relativePath: { eq: "work/bpm/featured-description-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    bpmMobile: file(relativePath: { eq: "work/bpm/featured-description-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    braenDesktop: file(relativePath: { eq: "work/braen-stone/featured-description-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    braenTablet: file(relativePath: { eq: "work/braen-stone/featured-description-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    braenMobile: file(relativePath: { eq: "work/braen-stone/featured-description-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    marcosDesktop: file(relativePath: { eq: "work/marcos/featured-description-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    marcosTablet: file(relativePath: { eq: "work/marcos/featured-description-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    marcosMobile: file(relativePath: { eq: "work/marcos/featured-description-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    marcoscsrDesktop: file(relativePath: { eq: "work/marcos-csr/featured-description-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    marcoscsrTablet: file(relativePath: { eq: "work/marcos-csr/featured-description-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    marcoscsrMobile: file(relativePath: { eq: "work/marcos-csr/featured-description-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ricohDesktop: file(relativePath: { eq: "work/ricoh/featured-description-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ricohTablet: file(relativePath: { eq: "work/ricoh/featured-description-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ricohMobile: file(relativePath: { eq: "work/ricoh/featured-description-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    weichertDesktop: file(relativePath: { eq: "work/weichert/featured-description-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    weichertTablet: file(relativePath: { eq: "work/weichert/featured-description-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    weichertMobile: file(relativePath: { eq: "work/weichert/featured-description-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`

import React from "react"
import { Link } from "gatsby"
import { Button } from "@components/Button"
import workData from "@json/work.json"

export const Pagination = ({ client }) => {
  const { next } = workData[client].pagination
  const { name, slug, headline, theme } = workData[next]

  return (
    <section className={`${theme.background} pt-50 pb-clamp-30-36`}>
      <div className="container grid-cols-12 lg:grid">
        <div className="col-span-8 col-start-2">
          <div className={theme.navigationColor}>
            <Link data-aos="fade-up" to={`/work/${slug}`} className="font-bold text-clamp-26-32 sm:hover:underline">
              {name}
            </Link>
            <h3 data-aos="fade-up" className="text-clamp-40-60 mt-7">
              {headline}
            </h3>
          </div>
        </div>
      </div>
      <div className="container flex justify-between lg:grid lg:grid-cols-12 mt-clamp-26-32">
        <div data-aos="fade-left" className="col-span-3 col-start-2">
          <Button link="/work" className={`${theme.navigationColor}`} reverse={true}>
            Back to all projects
          </Button>
        </div>
        <div data-aos="fade-right" className="col-span-2 col-start-11 text-right lg:text-left">
          <Button link={`/work/${slug}`} className={`${theme.navigationColor}`}>
            Next project
          </Button>
        </div>
      </div>
    </section>
  )
}
